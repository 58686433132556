import React, { useEffect, useState, useRef } from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Skeleton from "react-loading-skeleton";
import { ApiService } from "../../Components/Services/apiservices";
import PackageFilter from "./packagefilter";
let apiServices = new ApiService();
// import PackagesListSideBar from "../PackagesListSideBar";
function Packages() {
    const [categoryData, setCategoryData] = useState([])
    const [packages, setPackages] = useState([])
    const [packagesImageUrl, setPackagesImageUrl] = useState('')
    const [defaultImage, setDefaultImage] = useState('')
    const [loading , setLoading] = useState(false)
      const didMountRef = useRef(true)
    const updateFilterData = (newFilterData) => {
      setCategoryData(newFilterData.responseData.categoryData)
      console.log('term',newFilterData)
    };
    useEffect(() => {
      if (didMountRef.current) {
        setLoading(true)
        const dataString = {
          // ...
        };
        apiServices.packageslistPostRequest(dataString).then((res) => {
          if (res.data.status === "success") {
            if (res.data.categoryData.length > 0) {
              setCategoryData(res.data.categoryData);
              // setPackages(res.data.categorydata.packagesData)
              setLoading(false)
              setPackagesImageUrl(res.data.packages_url);
              setDefaultImage(res.data.default_image_baseurl);
            } else {
              setLoading(false)
              console.log("No products found");
            }
          }
        });
      }
      didMountRef.current = false;
    }, []);
    return (
        <>
            <Header />
    

            <div className="edu-breadcrumb-area edu-breadcrumb-area-small">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>Packages</h1>
              <div className="breadcrumb-inner">
                <ul className="edu-breadcrumb">
                  <li className="breadcrumb-item"><a href="/">Home</a></li>
                  <li className="separator"><i className="icon-angle-right"></i></li>
                  <li className="breadcrumb-item">Packages</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="section-gap-equal-small">
        <div className="container">
          <div className="row">
         

            <div className="col-lg-12">
            {loading == true ?
                <>
                <div className="row" >
                    <div className="section-title section-left" style={{marginBottom:"15px"}}>
                      <h3 className="title"> <Skeleton varient="rectangular" width={200} height={20} /></h3>
                      </div>
                   
                        <div className="col-lg-4 mb-5"  >
                          <div className="edu-course course-style-1 hover-button-bg-white" style={{border:"1px solid #ddd"}}>
                            <div className="inner">
                              <div className="thumbnail">
                              <Skeleton varient="rectangular" width={380} height={200} />
                              </div>
                              <div className="content">
                                <h6 className="title">
                                <Skeleton varient="rectangular" width={200} height={20} />
                                </h6>
                                <div className="course-price">
                                <Skeleton varient="rectangular" width={200} height={20} />
                                </div>
                               
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 mb-5"  >
                          <div className="edu-course course-style-1 hover-button-bg-white" style={{border:"1px solid #ddd"}}>
                            <div className="inner">
                              <div className="thumbnail">
                              <Skeleton varient="rectangular" width={380} height={200} />
                              </div>
                              <div className="content">
                                <h6 className="title">
                                <Skeleton varient="rectangular" width={200} height={20} />
                                </h6>
                                <div className="course-price">
                                <Skeleton varient="rectangular" width={200} height={20} />
                                </div>
                               
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 mb-5"  >
                          <div className="edu-course course-style-1 hover-button-bg-white" style={{border:"1px solid #ddd"}}>
                            <div className="inner">
                              <div className="thumbnail">
                              <Skeleton varient="rectangular" width={380} height={200} />
                              </div>
                              <div className="content">
                                <h6 className="title">
                                <Skeleton varient="rectangular" width={200} height={20} />
                                </h6>
                                <div className="course-price">
                                <Skeleton varient="rectangular" width={200} height={20} />
                                </div>
                               
                              </div>
                            </div>
                          </div>
                        </div>
                      
                    </div>
                <div className="row" >
                    <div className="section-title section-left" style={{marginBottom:"15px"}}>
                      <h3 className="title"> <Skeleton varient="rectangular" width={200} height={20} /></h3>
                      </div>
                   
                        <div className="col-lg-4 mb-5"  >
                          <div className="edu-course course-style-1 hover-button-bg-white" style={{border:"1px solid #ddd"}}>
                            <div className="inner">
                              <div className="thumbnail">
                              <Skeleton varient="rectangular" width={380} height={200} />
                              </div>
                              <div className="content">
                                <h6 className="title">
                                <Skeleton varient="rectangular" width={200} height={20} />
                                </h6>
                                <div className="course-price">
                                <Skeleton varient="rectangular" width={200} height={20} />
                                </div>
                               
                              </div>
                            </div>
                          </div>
                        </div>
                      
                    </div>
                    </>
             : <>{categoryData.length > 0 ? (
              
                categoryData.map((category, index) => (
                  category.packages.length > 0 ? (
                    <div className="row" key={index}>
                    <div className="section-title section-left" style={{marginBottom:"15px"}}>
                      <h3 className="title">{category.category_name}</h3>
                      </div>
                      {category.packages.map((subvalue, subIndex) => (
                        <div className="col-lg-4 mb-5" key={subIndex} >
                          <div className="edu-course course-style-1 hover-button-bg-white" style={{border:"1px solid #ddd"}}>
                            <div className="inner">
                              <div className="thumbnail">
                                <a href={`/packages/${subvalue.packages_slug}`}>
                                  <img
                                    src={
                                      subvalue.packages_image != null
                                        ? packagesImageUrl + subvalue.packages_image
                                        : defaultImage
                                    }
                                    alt="Packages"
                                  />
                                </a>
                              </div>
                              <div className="content">
                                <h6 className="title">
                                  <a href={`/packages/${subvalue.packages_slug}`}>{subvalue.packages_name}</a>
                                </h6>
                                <div className="course-price">
                                  ${subvalue.packages_usd_start} - ${subvalue.packages_usd_upto}
                                </div>
                                <a href={`/packages/${subvalue.packages_slug}`} className="edu-btn btn-secondary btn-small">
                                  Enquiry Now  <i className="icon-4"></i>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ):
                 ''
                ))
              ) : (
                <p style={{ color: "red" }}>No Packages found</p>
              )}</>
            }
            </div>
          </div>
        </div>
      </section>
            {/* <PackagesListSideBar/>   */}
            <Footer />
        </>
    )
}
export default Packages